import { FC } from 'react'
import NavigationLink from '../../01_atoms/NavigationLink/NavigationLink'
import LanguageSwitch from '../../01_atoms/LanguageSwitch/LanguageSwitch'
import RoutePaths from '../../../structures/enums/Routes.enum'

const Banner: FC<Record<string, never>> = () => {
    return (
        <nav className='navbar navbar-expand-lg bg-secondary'>
            <div className='container-fluid px-5 my-4'>
                <div className='container d-flex justify-content-between'>
                    <ul className='navbar-nav'>
                        <li className='me-3'>
                            <NavigationLink
                                linkPath={RoutePaths.ROOT}
                                label='navigation.home'
                            />
                        </li>
                        <li className='mx-3'>
                            <NavigationLink
                                linkPath={RoutePaths.ENTREPRENEURSHIP}
                                label='navigation.entrepreneurship'
                            />
                        </li>
                        <li className='mx-3'>
                            <NavigationLink
                                linkPath={RoutePaths.EDUCATION}
                                label='navigation.education'
                            />
                        </li>
                        <li className='mx-3'>
                            <NavigationLink
                                linkPath={RoutePaths.PERSONALDEVELOPMENT}
                                label='navigation.personalDevelopment'
                            />
                        </li>
                        <li className='mx-3'>
                            <NavigationLink
                                linkPath={RoutePaths.CONTACT}
                                label='navigation.contact'
                            />
                        </li>
                    </ul>
                    <LanguageSwitch />
                </div>
            </div>
        </nav>
    )
}

export default Banner
