import { FC } from 'react'
import Banner from '../../03_organisms/Banner/Banner'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/img/BeFree_logo.png'
import waterbrand from '../../../assets/img/BeFree_watermerk.png'

const Entrepreneurship: FC<Record<string, never>> = () => {
    const { t } = useTranslation()

    /*
                    <h3>{t('')}</h3>
                <p>{t('')}</p>
                <h3>{t('')}</h3>
                <p>{t('')}</p>
                <h3>{t('')}</h3>
                <p>{t('')}</p>
                <h3>{t('')}</h3>
                <p>{t('')}</p>
                <h3>{t('')}</h3>
                <p>{t('')}</p>
     */

    return (
        <div className='bg-light'>
            <Banner />
            <main className='container mainArea py-5'>
                <h1>{t('entrepreneurship.title')}</h1>
                <p>{t('entrepreneurship.intro')}</p>
                <h3>{t('entrepreneurship.studentJobsTitle')}</h3>
                <p>{t('entrepreneurship.studentJobsText')}</p>
                <h3>{t('entrepreneurship.dorstStudentJobTitle')}</h3>
                <p>{t('entrepreneurship.dorstStudentJobText')}</p>
                <h3>{t('entrepreneurship.innovItTitle')}</h3>
                <div className='row'>
                    <div className='col-md-9'>
                        <p>{t('entrepreneurship.innovItText')}</p>
                    </div>
                    <div className='col-md-3'>
                        <img className='img-fluid' src={logo} alt='BeFree logo' />
                        <img className='img-fluid' src={waterbrand} alt='BeFree waterbrand' />
                    </div>
                </div>

                <h3>{t('entrepreneurship.anysurferTitle')}</h3>
                <p>
                    {t('entrepreneurship.anysurferText')}
                    <a className="entrepreneurLink" href="http://www.provelo.be/nl">Provélo</a>
                    {t('entrepreneurship.anysurferText2')}
                    <a className="entrepreneurLink" href="https://www.fotomuseum.be/">Fotomuseum Antwerpen</a>
                    {t('entrepreneurship.anysurferText3')}
                </p>
                <h3>{t('entrepreneurship.metierTitle')}</h3>
                <p>{t('entrepreneurship.metierText')}</p>
                <h3>{t('entrepreneurship.NGBETitle')}</h3>
                <p>{t('entrepreneurship.NGBEText')}</p>
                <h3>{t('entrepreneurship.studyTripTitle')}</h3>
                <p>{t('entrepreneurship.studyTripText')}</p>
                <h3>{t('entrepreneurship.webinarTitle')}</h3>
                <p>{t('entrepreneurship.webinarText')}</p>
            </main>
        </div>
    )
}

export default Entrepreneurship
