import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import RoutePaths from './structures/enums/Routes.enum'

import './App.css';
import Home from './components/04_pages/Home/Home'
import PersonalDevelopment from './components/04_pages/PersonalDevelopment/PersonalDevelopment'
import Entrepreneurship from './components/04_pages/Entrepreneursship/Entrepreneurship'
import Education from './components/04_pages/Education/Education'
import Contact from './components/04_pages/Contact/Contact'

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route
                index
                path={RoutePaths.ROOT}
                element={<Home />}
            />
            <Route
                path={RoutePaths.PERSONALDEVELOPMENT}
                element={<PersonalDevelopment />}
            />
            <Route
                path={RoutePaths.ENTREPRENEURSHIP}
                element={<Entrepreneurship />}
            />
            <Route
                path={RoutePaths.EDUCATION}
                element={<Education />}
            />
            <Route
                path={RoutePaths.CONTACT}
                element={<Contact />}
            />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
