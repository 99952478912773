import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Banner from '../../03_organisms/Banner/Banner'
import profile from '../../../assets/img/profile.jpg'

const Home: FC<Record<string, never>> = () => {
    const { t } = useTranslation()

    return (
        <div className='bg-light'>
            <Banner />
            <main className='container mainArea pt-5 vh-100'>
                <h1>{t('home.title')}</h1>
                <p>{t('home.intro')}</p>
                <h3>{t('home.whoAmITitle')}</h3>
                <div className='row'>
                    <div className='col-md-8'>
                        <p>{t('home.whoAmIText1')}</p>
                        <p>{t('home.whoAmIText2')}</p>
                        <p>{t('home.whoAmIText3')}</p>
                    </div>
                    <div className='col-md-4'>
                        <img className='img-fluid' src={profile} alt='profile'/>
                    </div>
                </div>


                <h3>{t('home.hobbiesTitle')}</h3>
                <p>{t('home.hobbiesText1')}</p>
                <p>{t('home.hobbiesText2')}</p>
                <p>{t('home.hobbiesText3')}</p>
                <p>{t('home.hobbiesText4')}</p>
            </main>
        </div>
    )
}

export default Home
