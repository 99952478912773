import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface INavigationLinkLabelProperties {
    active?: boolean
    label: string
}

const NavigationLinkLabel: FC<INavigationLinkLabelProperties> = ({active, label}) => {
    const { t } = useTranslation()

    return (
        <div>
            <span className='textDecoration'>
                {t(`${label}`)}
            </span>
        </div>
    )
}

export default NavigationLinkLabel
