import { FC } from 'react'
import Banner from '../../03_organisms/Banner/Banner'
import { useTranslation } from 'react-i18next'

const PersonalDevelopment: FC<Record<string, never>> = () => {
    const { t } = useTranslation()

    return (
        <div className='bg-light'>
            <Banner />
            <main className='container mainArea pt-5 vh-100'>
                <h1>{t('personalDevelopment.title')}</h1>
                <p>{t('personalDevelopment.intro')}</p>
                <h3>{t('personalDevelopment.discTitle')}</h3>
                <p>{t('personalDevelopment.discText')}</p>
                <h3>{t('personalDevelopment.MBTITitle')}</h3>
                <p>{t('personalDevelopment.MBTIText')}</p>
                <h3>{t('personalDevelopment.EnneagramTitle')}</h3>
                <p>{t('personalDevelopment.EnneagramText')}</p>
            </main>
        </div>)
}

export default PersonalDevelopment
