import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Banner from '../../03_organisms/Banner/Banner'

const Education: FC<Record<string, never>> = () => {
    const { t } = useTranslation()
    return (
        <div className='bg-light'>
            <Banner />
            <main className='container mainArea pt-5 vh-100'>
                <h1>{t('education.title')}</h1>
                <p>{t('education.intro')}</p>
                <h3>{t('education.secundairTitle')}</h3>
                <p>{t('education.secundairText1')}</p>
                <p>{t('education.secundairText2')}</p>
                <h3>{t('education.furtherStudiesTitle')}</h3>
                <p>{t('education.furtherStudiesText')}</p>
            </main>
        </div>
    )
}

export default Education
