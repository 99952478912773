import { FC } from 'react'
import Banner from '../../03_organisms/Banner/Banner'
import LinkedIn from '../../00_fundament/Logo/LinkedIn'
import Telephone from '../../00_fundament/Logo/Telephone'
import Mail from '../../00_fundament/Logo/Mail'
import Facebook from '../../00_fundament/Logo/Facebook'

const Contact: FC<Record<string, never>> = () => {
    return (
        <div className='bg-light'>
            <Banner />
            <main className='mainArea container pt-5 vh-100'>
                <div className='row'>
                    <div className='col-md-4 my-3'>

                    </div>
                    <div className='col-md-1 my-3'>
                        <Telephone />
                    </div>
                    <div className='col-md-4 my-3 contactText'>
                        +32 470 35 65 46
                    </div>
                    <div className='col-md-3 my-3'>

                    </div>
                    <div className='col-md-4 my-3'>

                    </div>
                    <div className='col-md-1 my-3'>
                        <LinkedIn />
                    </div>
                    <div className='col-md-4 my-3'>
                        <a className='contactText' href="https://www.linkedin.com/in/laurenscorijn/">Laurens Corijn</a>
                    </div>
                    <div className='col-md-3 my-3'>

                    </div>
                    <div className='col-md-4 my-3'>

                    </div>
                    <div className='col-md-1 my-3'>
                        <Mail />
                    </div>
                    <div className='col-md-4 my-3'>
                        <a className='contactText' href="mailto:laurens.corijn.y7373@student.hogent.be">laurens.corijn.y7373@student.hogent.be</a>
                    </div>
                    <div className='col-md-3 my-3'>

                    </div>
                    <div className='col-md-4 my-3'>

                    </div>
                    <div className='col-md-1 my-3'>
                        <Mail />
                    </div>
                    <div className='col-md-4 my-3'>
                        <a className='contactText' href="mailto:laurenscorijn@live.be">laurenscorijn@live.be</a>
                    </div>
                    <div className='col-md-3 my-3'>

                    </div>
                    <div className='col-md-4 my-3'>

                    </div>
                    <div className='col-md-1 my-3'>
                        <Facebook />
                    </div>
                    <div className='col-md-4 my-3'>
                        <a className='contactText' href="https://www.facebook.com/laurens.corijn.1">Laurens Corijn</a>
                    </div>
                    <div className='col-md-3 my-3'>

                    </div>
                </div>
            </main>
        </div>)
}

export default Contact
